import React from 'react'
import { graphql } from 'gatsby'
import { TestimonialsPageTemplate } from 'precision/pages/testimonials'

const TestimonialsPage = ({ data }) => {
  console.log(data.allHomeadvisorJson)
  const { allHomeadvisorJson } = data
  return <TestimonialsPageTemplate {...allHomeadvisorJson} />
}
export default TestimonialsPage

export const testimonialsPageQuery = graphql`
  query TestimonialsPage {
    allHomeadvisorJson {
      nodes {
        date
        id
        rating {
          customerService
          overall
          quality
          valueForMoney
        }
        review {
          author
          comments
          location
          project
          response
        }
      }
    }
  }
`
