import React from 'react'

import {
  Flex,
  Box,
  Heading,
  Card,
  Text,
  Icon,
  HorizontalRule,
  StarRating,
  Truncate,
  Container,
} from 'components'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const handleOnDragStart = e => e.preventDefault()
const Testimonial = ({ children, name, city, rating, ...rest }) => {
  return (
    <Container.xs
      col
      onDragStart={handleOnDragStart}
      height={400}
      {...rest}
      mt={24}
    >
      <Card col height={260} hAlignContent="center" width="18m">
        <Flex p={16}>
          <StarRating
            ratingType="AAA"
            rating={rating}
            justifyContent="center"
          />
        </Flex>
        <Truncate length={200}>{children}</Truncate>
      </Card>
      <Text fontWeight="bold" textAlign="center">
        {name}
      </Text>
      <Text textAlign="center">{city}</Text>
    </Container.xs>
  )
}
const responsive = {
  xl: {
    breakpoint: { max: 4000, min: 1800 },
    items: 5,
    slidesToSlide: 5, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1800, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 672 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 672, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}

const TestimonialsPageTemplate = ({ nodes }) => {
  return (
    <>
      <Flex bg="brand.primary" p={16} hAlignContent="center" color="white">
        <Icon name="formatQuote" size={60} />
        <HorizontalRule />
        <Heading.h1 textAlign="center">
          What our client's are saying about us
        </Heading.h1>
      </Flex>
      <Flex
        grow
        bg="whites.alice"
        vAlignContent="center"
        hAlignContent="center"
      >
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false} //too many dots on mobile. need to hide only on mobile
          responsive={responsive}
          ssr={false} // how to do SSR with gatsby?
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={6000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          css={{ width: '100%', height: '100%' }}
        >
          {nodes.map(({ date, rating, review }) => (
            <Testimonial
              rating={rating.overall}
              name={review.author}
              city={review.location}
            >
              {review.comments}
            </Testimonial>
          ))}
        </Carousel>
      </Flex>
    </>
  )
}

export default TestimonialsPageTemplate
